.choosefile_button {
  visibility: hidden;
}

.choosefile_lable {
  background-color: $primaryMain;
  color: white;
  padding: 0.7rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  cursor: pointer;
}
